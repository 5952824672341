.select-search-input {
  background-color:transparent;
  border: 1.5px solid #A7A7A7;
  border-radius: 4px;
  color: #000000;
  max-height: 40px;
}
.select-search-container{
  width: 100%;
}
.select-search-input:focus {
  border-color: #FECD20 !important;
}

.select-search-select {
  background-color:#FFFFFF;
  border: 1.5px solid #A7A7A7;
  border-radius: 4px;
}

.select-search-options{
  background-color:#FFFFFF;
  padding-left:1rem;
}

.select-search-row button {
  background-color: transparent;
  color:#000000
}

.select-search-row:hover {
  background-color: #f0f0f0 !important;
  color:#000000
}
.select-search-row button:hover {
  background-color: #f0f0f0 !important;
  color:#000000
}

