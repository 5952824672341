.container {
  position: relative;
}

.container [class*="-marker-view"]:hover {
  z-index: 1;
}

.container .clusterSection {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  font-size: 30px;
  color: #F2F4F7;
}

.container .profileCluster{
  border-radius: 50%;
  border: 1px solid #E4E7EC;
  height: 65px;
  width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .show {
  display: flex !important;
}

.container .clusterSection:hover .imgSection {
  display: flex;
}
