.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border-radius: 16px;
  padding: 20px;
  line-height: 1.125em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  /* background-color: #f0f0f0; */
  color: #e6e6e6;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f6f6f6;
  border-radius: 8px;
}

.react-calendar__navigation__label__labelText {
  font: inherit;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  font: inherit;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */

.react-calendar__month-view__days__day {
  color: #666666;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #B3B3B3;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  /* color: #666666; */
  border-radius: 8px;
}

.react-calendar__tile:disabled {
  /* background-color: #f0f0f0; */
  color: #e6e6e6;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f6f6f6;
}

.react-calendar__tile--now {
  border: 1px solid #FECD20 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f6f6f6;
}

.react-calendar__tile--hasActive {
  background: #FECD20;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #FFC700;
}

.react-calendar__tile--active {
  background: #FECD20;
  border-radius: 8px;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #FFC700;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
