.bezel {
    max-width: 355px;
    overflow: hidden;
    border: 10px solid #000;
    border-radius: 40px;;
}

.screen {
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 30px;
    clip-path: inset(0 round 0 0 30px);
    overflow-y: scroll;
    box-sizing: content-box;
}

.screen::-webkit-scrollbar {
    display: none;
}

.bar {
    height: 30px;
    background: #262D30;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: sticky;
    top: 0;
    z-index: 10;
}

.clock {
    flex: 3;
    color: white;
    font-size: 14px;
    text-align: center;
}

.sensor {
    width: 10px;
    height: 10px;
    background: #3C4245;
    border-radius: 5px;
}

.left {
    margin-right: 10px;
}

.right {
    margin-left: 10px;
}

.speaker {
    width: 40px;
    height: 5px;
    background: #3C4245;
    border-radius: 5px;
}

.notch {
    flex: 4;
    background-color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-bottom: 10px; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.status {
    flex: 3;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
}

.icon {
    background-color: white;
}