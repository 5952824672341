.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 21.76px;
}

.video-responsive:not(:last-child) {
  margin-bottom: 15px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}